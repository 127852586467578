@import '../../assets/styles';

.wrapBox {
  position: fixed;
  top: 12vh;
  right: 0;
  left: 0;
  z-index: $zIndex-message;

  @include mobile {
    top: 5vh;
    padding: 0 em(16);
  }
}

.component {
  border: em(1) solid transparent;
  box-sizing: border-box;
  border-radius: em(10);
  overflow: hidden;
  width: fit-content;
  margin: auto;

  &_success {
    border-color: $standard-green;
    color: $standard-green;
  }

  &_failed {
    border-color: $standard-red;
    color: $standard-red;
  }

  &_pending {
    background: $gradient-orange-light-purple border-box;
    color: $standard-purple-light;
  }
}

.inner {
  display: flex;
  align-items: center;
  width: fit-content;
  background: $standard-purple-regular2;
  padding: em(16);
}

.img {
  max-width: em(30);
}

.content {
  font-size: em(14);
  padding-left: em(24);

  @include desktop {
    font-size: em(16);
  }
}
