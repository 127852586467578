@import '../../assets/styles';

.Container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: em(20) em(32);
  box-sizing: border-box;
  z-index: $zIndex-navigations;

  a {
    color: $standard-beige-light;
    text-decoration: none;
  }

  @include mobile {
    position: fixed;
    width: 100%;
    top: 0;
    background: $standard-background;
    padding: em(16);
  }
}

.home {
  display: flex;
  flex: 1;
}

.logo {
  @include mobile {
    width: em(120);
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.buttonBox {
  text-align: right;
  border: em(1) solid transparent;
  border-radius: em(8);
  background: $gradient-orange-light-purple border-box;
}

.outers {
  display: none;
  align-items: center;

  @include desktop {
    display: flex;
  }
}

.outLink {
  display: flex;
  align-items: center;
  margin-right: em(36);
}

.linkIcon {
  width: em(24);
  height: em(20);
}
