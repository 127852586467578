@font-face {
  font-family: 'BerkshireSwash';
  src: url('./Berkshire_Swash/BerkshireSwash-Regular.ttf');
}

@font-face {
  font-family: 'Archivo Light';
  src: url('./Archivo/Archivo-Light.ttf');
}

@font-face {
  font-family: 'Archivo Regular';
  src: url('./Archivo/Archivo-Regular.ttf');
}

@font-face {
  font-family: 'Archivo Medium';
  src: url('./Archivo/Archivo-Medium.ttf');
}

@font-face {
  font-family: 'Archivo SemiBold';
  src: url('./Archivo/Archivo-SemiBold.ttf');
}

@font-face {
  font-family: 'Archivo Bold';
  src: url('./Archivo/Archivo-Bold.ttf');
}
