@import '../../assets/styles';

.dots {
  display: flex;
  justify-content: center;
  padding: em(32);
}

.dot {
  width: em(14);
  height: em(14);
  border-radius: 50%;
  background-color: $standard-purple-greyed;
  animation: 700ms ease 0ms infinite;
  margin: 0 em(6);

  @include desktop {
    width: em(16);
    height: em(16);
  }

  &_0 {
    animation-name: color0;
  }

  &_1 {
    animation-name: color1;
  }

  &_2 {
    animation-name: color2;
  }
}

@keyframes color0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.66;
  }

  100% {
    opacity: 0.33;
  }
}

@keyframes color1 {
  0% {
    opacity: 0.33;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.66;
  }
}

@keyframes color2 {
  0% {
    opacity: 0.66;
  }

  50% {
    opacity: 0.33;
  }

  100% {
    opacity: 1;
  }
}
