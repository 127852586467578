$standard-background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #290d2e;
$modal-background: radial-gradient(
  151.64% 120.36% at 47.19% -0.63%,
  rgba(27, 9, 30, 0.8) 0%,
  rgba(27, 9, 30, 0.8) 100%
);
$card-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #331338;
$navs-background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #331338;

$standard-overall: #17071b;

$standard-purple-regular: #331338;
$standard-purple-regular2: #402245;
$standard-purple-light: #f3d9ff;
$standard-beige-dark: #f7d6a0;
$standard-beige-light: #fff6e7;
$standard-orange: #ea662e;
$standard-grey: #d6d6d6;
$standard-purple-greyed: #7d657e;
$standard-red: #fd4444;
$standard-green: #33d9a4;

$gradient-orange-purple: linear-gradient(92.68deg, #ea662e 1.68%, #cf6a80 47.9%, #6e57c9 108.66%);
$gradient-orange-light-purple: linear-gradient(
  264.38deg,
  #9774e2 4.88%,
  #cf6a80 43.56%,
  #ef6d36 112.52%
);
$gradient-purple-blue: linear-gradient(
  234.24deg,
  rgba(23, 81, 150, 0.65) 17.54%,
  rgba(75, 0, 68, 0.65) 70.83%
);
