$mobile-max-width: 576px;
$tablet-max-width: 992px;

@mixin mobile {
  @media (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: calc($mobile-max-width + 1px)) and (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: calc($tablet-max-width + 1px)) {
    @content;
  }
}
