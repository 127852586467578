@import '../../assets/styles';

.ConnectButton {
  color: $standard-beige-light !important;
  background: $standard-background !important;
  font-family: 'Archivo Regular' !important;
  font-weight: normal !important;
  width: 100% !important;
  justify-content: center;
  border-radius: em(8) !important;
  line-height: unset !important;
  height: em(48) !important;
  font-size: em(14) !important;

  @include desktop {
    font-size: em(16) !important;
    width: em(172) !important;
  }
}
