@import '../../assets/styles';

.links {
  flex: 2;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  background: $navs-background;
  padding: em(18) 0;

  @include desktop {
    justify-content: center;
    position: relative;
    background: transparent;
    padding: 0;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @include desktop {
    margin: 0 2vw;
    flex: none;

    &_hiden {
      display: none;
    }
  }
}

.icon {
  display: flex;
  margin-bottom: em(8);

  @include desktop {
    display: none;
  }
}

.text {
  font-size: em(12);
  color: $standard-purple-greyed;

  &_active {
    background: $gradient-orange-light-purple;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-clip: text;
  }

  @include tablet {
    font-size: em(14);
  }

  @include desktop {
    font-size: em(20);
    color: $standard-beige-light;

    &_active {
      font-weight: 600;
      background: transparent;
      -webkit-text-fill-color: initial;
      -moz-text-fill-color: initial;
    }
  }
}
