@import './assets/fonts';
@import './assets/styles';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  background: $standard-background;
  color: $standard-beige-light;
  font-family: 'Archivo Regular', Helvetica, Arial, sans-serif;
  letter-spacing: em(0.32);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root {
  width: inherit;
  height: inherit;
}

.wallet-adapter-modal {
  background: $modal-background !important;
  backdrop-filter: blur(em(10));
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-adapter-modal-container {
  min-height: min-content !important;
  border: em(1) solid transparent;
  border-radius: em(10);
  background: $gradient-orange-light-purple border-box;
}

.wallet-adapter-modal-wrapper {
  background: $standard-background !important;
}

.wallet-adapter-modal-overlay {
  background: transparent !important;
}

.wallet-adapter-dropdown-list {
  background: $standard-purple-regular !important;

  @include desktop {
    width: em(155) !important;
  }
}

input::-webkit-input-placeholder {
}

input {
  &::placeholder {
    color: #fff;
    opacity: 0.75;
  }

  &::-webkit-input-placeholder {
    color: #fff;
    opacity: 0.75;
  }

  &:-moz-placeholder {
    color: #fff;
    opacity: 0.75;
  }

  &:-ms-input-placeholder {
    color: #fff;
    opacity: 0.75;
  }

  ::-ms-input-placeholder {
    color: #fff;
    opacity: 0.75;
  }
}
