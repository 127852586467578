@import './assets/styles';

.AppContainer {
  width: 100%;
}

.AppBody {
  position: relative;

  @include mobile {
    padding: em(72) em(16) em(102);
  }

  @include tablet {
    padding: 0 em(28) em(104);
  }

  @include desktop {
    padding: em(48) 7vw;
  }
}
